<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
    >
      <v-text-field
        v-model="openingHourPair.starthours"
        :label="$t('from')"
        prepend-icon="mdi-clock"
        clearable
        filled
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-text-field
        v-model="openingHourPair.endhours"
        :label="$t('to')"
        prepend-icon="mdi-clock"
        clearable
        filled
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      sm="4"
    >
      <v-text-field
        v-model="openingHourPair.maximumSalesPerSlot"
        @keyup="maxSalesToFloat"
        prepend-icon="mdi-cash-multiple"
        :suffix="$t('ISK')"
        filled
        dense
        :label="$t('maxSalesPerSlot')"
      />
    </v-col>
    <v-col
      cols="12"
      sm="4"
    >
      <v-select
        v-model="openingHourPair.intervalBetweenSlotsInMillis"
        prepend-icon="mdi-clock-fast"
        :items="timeIntervalsInMillis"
        item-text="text"
        item-value="value"
        :label="$t('intervalBetweenSlots')"
        filled
        dense
      />
    </v-col>
    <v-col
      cols="12"
      sm="4"
    >
      <v-select
        v-model="openingHourPair.pauseBetweenSlotsInMillis"
        prepend-icon="mdi-pause-octagon"
        :items="timeIntervalsInMillis"
        item-text="text"
        item-value="value"
        :label="$t('pauseBetweenSlots')"
        filled
        dense
      />
    </v-col>
    <!--
    <v-col cols="12">
      <v-combobox
              prepend-icon="mdi-compare"
              :items="[]"
              label="Custom slots"
              multiple
              chips
              disabled
      ></v-combobox>
    </v-col>
    -->
    <v-col cols="12">
      <v-text-field
        v-model="openingHourPair.comment"
        :label="$t('comment')"
        prepend-icon="mdi-message-reply-text"
        clearable
        filled
        hide-details
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "OpeningHourPair",
    props: {
      openingHourPair: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        timeIntervalsInMillis: []
      }
    },
    methods: {
      maxSalesToFloat() {
        this.location.maximumSalesPerSlot = this.location.maximumSalesPerSlot.replace(/[^\d.-]/g, '')
      }
    },
    created() {
      let minutesInDay = []
      minutesInDay.push({value: null, text: '- ' + this.$t('noValue') + ' -'})
      for(let i = 0; i < 1440; i++) {
        let stringValue = ""
        let minutes = Math.floor(i % 60)
        let hours = Math.floor((i / 60) % 24)

        if(hours > 0){
          stringValue = hours + ' ' + this.$t('hoursAnd') + ' '
        }
        stringValue = stringValue + minutes + ' ' + this.$t('minutesLC')

        minutesInDay.push({value: i * 60000, text: stringValue})
      }
      this.timeIntervalsInMillis = minutesInDay
    },
  }
</script>

<style scoped>

</style>
